import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({

  breakpoints: {
    base: '0em', // 0px
    sm: '30em',  // ~480px
    md: '48em',  // ~768px
    lg: '62em',  // ~992px
    xl: '80em',  // ~1280px
    '2xl': '96em', // ~1536px
  },
  
  colors: {
    brand: {
      50: '#f7fafc',
      500: '#718096',
      900: '#171923',
    },
  },
});

export default theme;