import React from 'react';
import { 
  ChakraProvider, 
  Box, 
  Button, 
  VStack,
  ColorModeScript,
  Flex,
 } from '@chakra-ui/react';
 import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import theme from './theme';
import './i18n';

import Header from './components/Header';
import Home from './pages/Home';
import NotFound from './pages/NotFound';

import Phone from './components/Phone';

import './App.css'

function App() {
  const { t, i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'zh' : 'en';
    i18n.changeLanguage(newLang);
  };

  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Flex 
        direction='column' 
        bgColor='#F5F5F5' 
        display={{base: 'none', md: 'flex'}}
      >
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </Flex>

      {/* 移动端布局，在桌面端隐藏 */}
      <Box display={{base: 'block', md: 'none'}}>
        <Phone />
      </Box>
    </ChakraProvider>
  );
}

export default App;