
import { 
    Flex, 
    Image,
    Text,
    Divider,
 } from "@chakra-ui/react";



function Phone() {
    return (
        <Flex
          width='100%'
          height='100vh'
          justifyContent='center'
          overflow="hidden"
          alignItems='center'
          direction='column'
          bgImage="url('/PhoneBg.svg')"
          bgPosition="top"
          bgRepeat="no-repeat"
          bgSize="cover"
        >

            <Flex 
                alignItems='center'
            >
                <Image 
                    src='./xianColor.svg' 
                    alt='Xian Icon' 
                    width='38px' 
                />
                <Text 
                    ml='2'
                    mt='2'
                    color='#C6AF8D'
                    fontFamily='KangXi'
                    fontSize='28px' 
                    fontWeight='400'
                >仙门</Text>
                <Text 
                    ml='2'
                    color='#C6AF8D'
                    fontFamily='Source Han Serif CN'
                    fontSize='22px' 
                    fontWeight='400'
                >XianDAO</Text>
            </Flex>
            <Flex mt='10' mb='5'>
                <Text 
                    fontSize="30px" 
                    fontWeight="700" 
                    color="#C6AF8D"
                    lineHeight="43.11px"
                    letterSpacing="0.02em"
                    fontFamily="Source Han Serif CN"
                >Fulu Talisman NFT</Text>
            </Flex>
            <Divider 
                width="102px" 
                border="2px"
                color="#C6AF8D"
                lineHeight="43.11px"
                letterSpacing="0.02em"
                fontFamily="Source Han Serif CN"
            />
            <Flex
                mt='5'
                mb="6"
                direction="column"
            >
                <Text
                    fontSize="20px"
                    fontWeight="400"
                    color="#C5AF8D"
                    lineHeight="35px"

                    fontFamily="Source Han Serif CN"
                >屏小难展卷，电脑观天地</Text>
                <Text
                    fontSize="20px"
                    fontWeight="400"
                    color="#C5AF8D"
                >愿君移至电脑端细观全貌</Text>
            </Flex>
            <Flex pl='3'>
                <Image 
                    src="/Home/fuluIcon.svg" 
                    transition="all 0.3s cubic-bezier(0.4, 0, 0.2, 1)"

                    _hover={{
                        transform: 'scale(1.1)',
                        cursor: 'pointer', 
                        filter: 'brightness(1.1) contrast(1.1)'  
                    }}
                    _active={{
                        transform: 'scale(0.95)', 
                    }}
                />
            </Flex>
        </Flex>
    )
}

export default Phone;