import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// 翻译资源
const resources = {
  en: {
    translation: {
      welcome: 'Welcome',
      switchLanguage: '中文',
      fululist: 'List of talisman',
      word1: "The world's first Taoist religion PFP Ordinals NFT",
      word11: "",
      word2: 'A comprehensive fusion of religious, artistic, and portraiture elements',
      word22: "",
      word3: 'Each Ordinals wallet address section requires talisman NFT to increase luck'
    }
  },
  zh: {
    translation: {
      welcome: '欢迎',
      switchLanguage: 'English',
      fululist: '符箓一览',
      word1: '全球第一个道教的宗教PFP Ordinals NFT',
      word11: '',
      word2: '集合宗教、艺术与头像类型于一身',
      word22: '',
      word3: '每一个Ordinals钱包地址都需要符箓NFT来增加运气'
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
