import { useEffect } from 'react';

import { 
    Flex, 
    Image as chakraImage,
    Text,
 } from "@chakra-ui/react";

 import { motion } from 'framer-motion'
 import { chakra } from '@chakra-ui/react'


function ContentOne() {
  const MotionImage = chakra(motion.img)

  useEffect(() => {
    const img = new Image();
    img.src = '/Home/bg-1.webp';
  }, []);

    return (
        <Flex
          width='100%'
          height='100vh'
          backgroundImage='/Home/bg-1.webp'
          //bgImage={bgImage}
          bgPosition="center"
          bgRepeat="no-repeat"
          bgSize="cover"
          justifyContent='center'
          overflow="hidden"
          alignItems='center'
          direction='column'
        >
            <MotionImage 
              src='/Home/fuluIcon.svg' 
              alt='Xian Icon' 
              width='342px'
              animate={{
                y: [-10, 10, -10],
                transition: {
                  duration: 5, // 从2秒增加到5秒，动画会变慢
                  repeat: Infinity,
                  ease: "easeInOut"
                }
              }}
              whileHover={{
                scale: 1.1,
                cursor: 'pointer', 
                filter: 'brightness(1.1) contrast(1.1)'
              }}
              whileTap={{
                scale: 0.95
              }}
            />
            <Text
                fontSize='24px'
                fontWeight='700'
                color='#FFDEAA'
                lineHeight='34.49px'
                fontFamily="Source Han Serif CN" 
                letterSpacing="0.08em"          
                textAlign="right"              
            >
              Fulu Talisman，Chinese Taoism Rune
            </Text>
            
        </Flex>
    )
}

export default ContentOne;