
import { 
    Flex, 
    Image,
    Text,
    Box,
    AspectRatio,
 } from "@chakra-ui/react";

import { useTranslation } from 'react-i18next';


function ContentTwo() {

  const { t, i18n } = useTranslation();

    return (
        <Flex
            width='100%'
            height='200vh'
            //backgroundImage='/Home/bg.svg'
            bgImage="url('/Home/BG3 1.svg')"
            bgPosition="top"
            bgRepeat="no-repeat"
            bgSize="cover"
            //justifyContent='center'
            overflow="hidden"
            alignItems='center'
            direction='column'
        >
          <Flex
            height='50%'
            justifyContent='center'
            overflow="hidden"
            alignItems='center'
            direction='column'
          >
            <Flex
              width='800px'
              height='258px'
              bgImage="url('/Home/Mask1.svg')"
              alignItems='center'
              direction='column'
              position="relative"
              border="1px solid #466F77"
              _before={{
                content: '""',
                position: 'absolute',
                top: 0,
                left: -1,
                width: '22px',
                height: '18px',
                borderBottomRightRadius: '25px',
                borderRight: '1px solid #466F77',
                borderBottom: '1px solid #466F77'
              }}
              _after={{
                content: '""',
                position: 'absolute',
                top: 0,
                right: -1,
                width: '22px',
                height: '18px',
                borderBottomLeftRadius: '20px',
                borderLeft: '1px solid #466F77',
                borderBottom: '1px solid #466F77'
              }}
            >
              <Image mt="-3.5%" src="/Home/yzImg.svg" />
              <Flex
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <Flex>
                  <Text
                    color="#E4D5BF"
                    fontSize='42px'
                    fontFamily='楷体'
                  >符箓</Text>
                </Flex>
                <Text
                  color="#E4D5BF"
                  fontSize='26px'
                  fontFamily="Source Han Serif CN"
                >Fulu Talisman</Text>
              </Flex>
              <Flex
                mt='3'
                direction='column'
              >
                <Text 
                  fontSize="16px"
                  fontWeight="300"
                  lineHeight="22.99px"
                  letterSpacing="0.04em"
                  color="#E4D5BF"
                  fontFamily="Source Han Serif CN"
                >·  {t('word1')}</Text>
                <Text 
                  fontSize="16px"
                  fontWeight="300"
                  lineHeight="22.99px"
                  letterSpacing="0.04em"
                  color="#E4D5BF"
                  fontFamily="Source Han Serif CN"
                >{t('word11')}</Text>
                <Text 
                  fontSize="16px"
                  fontWeight="300"
                  lineHeight="22.99px"
                  letterSpacing="0.04em"
                  color="#E4D5BF"
                  fontFamily="Source Han Serif CN"
                >·  {t('word2')}</Text>
                <Text 
                  fontSize="16px"
                  fontWeight="300"
                  lineHeight="22.99px"
                  letterSpacing="0.04em"
                  color="#E4D5BF"
                  fontFamily="Source Han Serif CN"
                >{t('word22')}</Text>
                <Text 
                  fontSize="16px"
                  fontWeight="300"
                  lineHeight="22.99px"
                  letterSpacing="0.04em"
                  color="#E4D5BF"
                  fontFamily="Source Han Serif CN"
                >· {t('word3')}</Text>
              </Flex>
            </Flex>
            <AspectRatio
              width='800px' 
              height='400px'
              border="1px solid #466F77"
              ratio={4/3}
            >
              {/*
              <iframe
                title='naruto'
                src='/Home/BG3 1.svg'
                allowFullScreen
              />
              */}
              <Image src="/Home/bg.svg" alt='naruto' objectFit='cover' />
            </AspectRatio>
          </Flex>
         
          <Flex
            width='100%'
            height='100vh'
            position="relative"
            overflow="hidden"
            alignItems='center'
            direction='column'
          >
            <Box
              position="absolute"
              bottom="0"
              left="0"
              width="100%"
              height="80%"
              bgImage="url('/Home/Mask2.svg')"
              bgPosition="center"
              bgRepeat="no-repeat"
              bgSize="cover"
            />
            <Flex
              height="100vh"
              alignItems='center'
              justifyContent="center"
              direction='column'
            >
              <Text
                fontSize="36px"
                fontWeight="700"
                color="#E4D5BF"
              >{t('fululist')}</Text>
              <Box
                position="relative"
                zIndex={1}
                cursor="pointer"
                onClick={() => {
                  console.log('Image clicked');
                }}
              >
                <Image 
                  width="464px"
                  height="687px"
                  src="/Home/fuluImg.svg" 
                  transition="all 0.3s cubic-bezier(0.4, 0, 0.2, 1)"
                  _hover={{
                    transform: 'scale(1.1)',
                    filter: 'brightness(1.1) contrast(1.1)'  
                  }}
                  _active={{
                    transform: 'scale(0.95)', 
                  }}
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
    )
}

export default ContentTwo;