/* eslint-disable @typescript-eslint/no-unused-vars */

import { 
    Button, 
    MenuButton, 
    Box, 
    Flex, 
    Link, 
    Text,
    Image,
    Spacer
   } 
from '@chakra-ui/react';
import { Link as ChakraLink } from '@chakra-ui/react'

import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
  
function Header() {
  
    const location = useLocation();
  
    const linkStyle = (isActive: boolean) => ({
      width: "96px",
      height: "32px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textDecoration: "none",
      borderRadius: isActive ? "16px" : "0",
      backgroundColor: isActive ? "#F2F0FF" : "transparent",
      transition: "all 0.3s ease",
      _hover: {
        textDecoration: "none"
      }
    });
  
    const { t, i18n } = useTranslation();

    const toggleLanguage = () => {
        const newLang = i18n.language === 'en' ? 'zh' : 'en';
        i18n.changeLanguage(newLang);
    };
    
    return (
      <Flex 
        justifyContent='center'
        position="fixed" 
        top={0}        
        left={0}      
        right={0}       
        width="full"     
        zIndex={1000}
      >
        <Flex 
          width='100vw'
          height='50PX'
          bg="#183C43" 
          px={3} 
          direction='row'
          as="nav"
          align="center"
          justify="space-between"  
        >
          <Flex 
            alignItems='center'
          >
            <Image 
              src='./Header/xian.svg' 
              alt='Xian Icon' 
              width='36px' 
            />
            <Text 
              ml='2'
              mt='2'
              color='white'
              fontFamily='KangXi'
              fontSize='24px' 
              fontWeight='400'
            >仙门</Text>
            <Text 
              ml='2'
              color='white'
              fontFamily='Source Han Serif CN'
              fontSize='22px' 
              fontWeight='400'
            >XianDAO</Text>
          </Flex>
          <Flex
            // mt={{ base: 4, md: 0 }}
            mr={5}
            zIndex='2'
          >
            <ChakraLink href='https://twitter.com/graphmultiverse' className="link" isExternal>
              <Image
                src="/Header/tw.svg"
                alt="Vercel Logo"
                //className="dark:invert"
                width="30px"
                height="30px"
              />
            </ChakraLink>
            <Spacer marginRight={30} /> 
            <ChakraLink href='https://discord.gg/fwWwra6AZ8' className="link" isExternal>
              <Image
                src="/Header/dc.svg"
                alt="Vercel Logo"
                //className="dark:invert"
                width="30px"
                height="30px"
              />
            </ChakraLink>
            <Button 
              color="#998669"
              bgColor='white'
              height='30px'
              width='120px'
              borderRadius="10px"
              onClick={toggleLanguage}
              ml='5'
            >
              {t('switchLanguage')}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    );
  }
  
  export default Header;