import { Box, Heading } from '@chakra-ui/react';

function NotFound() {
  return (
    <Box p={4}>
      <Heading>404 - Page Not Found</Heading>
    </Box>
  );
}

export default NotFound;